<template>
  <div>
    <div id="print">
      <zoo-baza v-if="order.managers.length === 0 || order.managers[0].title === 'ZB'" :order="order"/>
      <zoo-market v-else-if="order.managers.length > 0 && order.managers[0].title === 'ZM'" :order="order" />
      <zoo-olx v-else :order="order" />
    </div>
  </div>
</template>

<script>
  import {APIService} from "../../services/api";
  import ZooBaza from "./components/ZooBaza";
  import ZooMarket from "./components/ZooMarket";
  import ZooOlx from "./components/ZooOlx";

  export default {
    name: 'PrintOrder',
    components: {
      ZooMarket,
      ZooBaza,
      ZooOlx,
    },
    props: ['id'],
    data: () => {
      return {
        order: null,
      }
    },
    async beforeMount() {
      try {
        const order = await APIService.get(`order/${this.id}?join=forms&join=managers&join=positions&join=deliveryDates&join=paymentMethods`)
        order.positions = order.positions.filter(position => !position.removed);
        this.order = order;
        setTimeout(() => {
          window.print();
          window.onafterprint = () => {
            this.$router.back();
          };
        }, 200);
      } catch (e) {
        console.error(e);
      }
    },
  }
</script>
